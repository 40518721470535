import { Outlet } from "@remix-run/react";
import { SignupSteps } from "~/components/auth/SignupSteps";
import { useSignupSteps } from "~/hooks/useSignupSteps";
import { Card } from "~/components/ui/Card";
import RangerLogoWithText from "~/components/shared/RangerLogoWithText";
import MagicBlob from "~/components/aesthetic/MagicBlob";
import grainNoise from "~/assets/images/grain_noise.svg";

export default function AuthLayout() {
  const { isSignupFlow } = useSignupSteps();

  return (
    <div className="relative flex min-h-screen flex-col items-center gap-4 overflow-hidden p-4 xs:p-6 md:gap-8 md:p-8 lg:justify-between">
      <MagicBlob className="absolute left-1/2 top-0 z-0 h-full max-h-full w-auto max-w-screen-2xl -translate-x-1/2 dark:brightness-[.3] dark:saturate-200 dark:filter md:h-auto md:w-full lg:top-1/2 lg:-translate-y-1/2 " />
      <div
        className="pointer-events-none absolute left-0 top-0 z-0 hidden h-full w-full md:block"
        style={{
          backgroundImage: `url(${grainNoise})`,
          backgroundSize: "50px 50px",
          mixBlendMode: "overlay",
        }}
      />
      {isSignupFlow ? (
        <SignupSteps className="z-10" />
      ) : (
        <div>{/* Centers `Card` correctly when on Login screen */}</div>
      )}
      <Card className="z-10 flex w-full max-w-md flex-col items-center gap-6 rounded-2xl p-6 shadow-2xl shadow-[rgba(65,3,94,.3)] xs:w-full md:mb-16 md:gap-10 md:p-8 lg:p-12 lg:pt-8">
        <RangerLogoWithText />
        <Outlet />
      </Card>
      <div>{/* Centers `Card` correctly */}</div>
    </div>
  );
}

import { Fragment, type HTMLProps } from "react";
import { useSignupSteps } from "~/hooks/useSignupSteps";
import { cn } from "~/utils/misc";
import { CheckIcon } from "lucide-react";

function getStepStyle(index: number, currentStepIndex: number) {
  if (index === currentStepIndex) {
    return "border-primary bg-primary text-primary-foreground";
  }
  if (index < currentStepIndex) {
    return "border-primary bg-primary text-primary-foreground";
  }
  return "border-primary text-primary";
}

function StepIndicator({
  index,
  currentStepIndex,
}: {
  index: number;
  currentStepIndex: number;
}) {
  return index < currentStepIndex ? <CheckIcon size={14} /> : <>{index + 1}</>;
}

export function SignupSteps({
  className,
}: {
  className?: HTMLProps<HTMLDivElement>["className"];
}) {
  const { steps, currentStepIndex, isSignupFlow } = useSignupSteps();

  if (!isSignupFlow) return null;

  return (
    <ol className={cn("flex items-center gap-4 sm:gap-5", className)}>
      {steps.map((step, index) => (
        <Fragment key={step.path}>
          <li className="flex items-center gap-1 sm:gap-2">
            <div
              className={cn(
                "flex h-5 w-5 items-center justify-center rounded-full border text-xs",
                getStepStyle(index, currentStepIndex),
              )}
            >
              <StepIndicator
                index={index}
                currentStepIndex={currentStepIndex}
              />
            </div>
            <span className="text-xs text-primary">{step.label}</span>
          </li>
          {index < steps.length - 1 && (
            <div
              className="hidden h-px w-4 bg-muted-foreground sm:block sm:w-6 md:w-10"
              aria-hidden="true"
            />
          )}
        </Fragment>
      ))}
    </ol>
  );
}
